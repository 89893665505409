import React from 'react'
import Layout from '../components/layout'
import Series from '../components/Series'
import { graphql } from 'gatsby'
import ContentBlock from '../components/contentBlock'
import SEO from '../components/seo'

const SeriesPage = ({ data }) => (
  <Layout>
    <SEO title={data.wordpressPage.title} />
    <ContentBlock>
      <h1>{data.wordpressPage.title}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: data.wordpressPage.content,
        }}
      />
    </ContentBlock>
    <Series artwork={data.allWordpressWpSeries.edges} />
  </Layout>
)

export default SeriesPage

export const query = graphql`
  query SeriesQuery {
    allWordpressWpSeries {
      edges {
        node {
          title
          id
          featured_media {
            source_url
            id
            localFile {
              childImageSharp {
                fluid(maxHeight: 1500) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          acf {
            series_link
          }
        }
      }
    }
    wordpressPage(slug: { eq: "fine-art-prints-template" }) {
      title
      content
    }
  }
`
