import React, { Fragment } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const SeriesContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  text-align: center;
  grid-gap: 20px;
  .imgContainer {
    max-width: 100%;
    position: relative;
    min-height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    @media (max-width: 480px) {
      max-width: calc(100% - 15px);
    }

    .gatsby-image-wrapper {
      position: initial !important;
    }
  }
  h2 {
    font-size: 1.4rem;
    color: #339966;
  }
  a {
    text-decoration: none;
  }
`

const Series = ({ artwork }) => (
  <SeriesContainer>
    {artwork.map(series => (
      <Fragment key={series.node.id}>
        <article>
          <a href={series.node.acf.series_link}>
            <div className={' imgContainer'} key={series.node.featured_media.id}>
              <Img
                fluid={series.node.featured_media.localFile.childImageSharp.fluid}
                alt={series.node.title}
                key={series.node.featured_media.wordpress_id}
              />
            </div>
            <h2>{series.node.title}</h2>
          </a>
        </article>
      </Fragment>
    ))}
  </SeriesContainer>
)

export default Series
